import styles from "./Route.module.scss";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { SwipeableDrawer } from "@mui/material";
import Header from "components/Shared/Header/Header";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import VisitCard from "components/Shared/VisitCard/VisitCard";
import DayScheduleBreak from "../DaySchedule/DayScheduleBreak/DayScheduleBreak";
import { Circle as CircleMarker, Plane as PlaneMarker } from "components/Shared/Marker";
import FlagIcon from "../../../assets/icons/mapMarkers/marker-flag.svg";
import { useContext, useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { useLocation } from "react-router-dom";
import {
  ManagerContext,
  RouterContext,
  UserDataContext,
} from "shared/Contexts";
import {
  DayScheduleStepsStatusesNew,
  DayScheduleStepsTabsTypeNew,
  DayScheduleStepsTypeNew,
  DayScheduleTypeNew,
  TrafficModelE,
  UnitSystemE,
} from "shared/Enums";

import { LatLngI, VisitBreaksNewI, VisitCardsNewI } from "@interfaces";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  MarkerF,
} from "@react-google-maps/api";

function RouteView() {
  const [routerValue, setRouterValue] = useContext(RouterContext);
  const [{ agent }] = useContext(ManagerContext);
  const { currentUser } = useContext(UserDataContext);
  const [map, setMap] = useState<google.maps.Map>();
  const [waypoints, setWaypoints] = useState<any>([]);
  const [missedAppointments, setMissedAppointments] = useState<any>([]);
  const [skippedSteps, setSkippedSteps] = useState<any>([]);
  const [completedSteps, setCompletedSteps] = useState<any>([]);
  const [startLocation, setStartLocation] = useState<any>(null);
  const [endLocation, setEndLocation] = useState<any>(null);
  const [startPoint, setStartPoint] = useState<any>(null);
  const [startLocationAngle, setStartLocationAngle] = useState<number>(0);
  const [response, setResponse] = useState<any>(null);
  const [opened, setOpened] = useState<boolean>(false);
  const [card, setCard] = useState<VisitCardsNewI | VisitBreaksNewI>();
  const [rerender, setRerender] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled
  } = useGeolocated({ userDecisionTimeout: 5000 });

  useEffect(() => {
    setRouterValue({ ...routerValue, opened: false });
    setOpened(false);
  }, [location]);

  useEffect(() => {
    if (routerValue.opened) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "inherit";
  }, [routerValue.opened]);

  useEffect(() => {
    const myLocation = agent && agent.profile.testMode.isTest && agent.profile.testMode.points
      ? {
        lat: +agent.profile.testMode.points.lat,
        lng: +agent.profile.testMode.points.lng,
      }
      : currentUser?.routerSettings
      && currentUser.routerSettings.testMode.isTest
      && currentUser.routerSettings.testMode.points
        ? {
          lat: currentUser.routerSettings.testMode.points.lat,
          lng: currentUser.routerSettings.testMode.points.lng,
        }
        : coords
          ? {
            lat: coords.latitude,
            lng: coords.longitude,
          }
          : undefined;

    setRouterValue({
      ...routerValue,
      myLocation,
    });
  }, [coords, currentUser?.routerSettings, agent]);

  useEffect(() => {
    setIsLoading(true);
    setWaypoints([]);
    setResponse(null);
    if (
      routerValue.loaded &&
      routerValue.routeData?.steps.activeSteps.length &&
      routerValue.opened &&
      coords
    ) {
      let startLoc: LatLngI;

      if (
        routerValue.routeData.day.status === DayScheduleTypeNew.started &&
        routerValue.myLocation
      ) {
        startLoc = routerValue.myLocation;
      } else {
        const startStep = routerValue.routeData.steps.activeSteps.find(
          (i) => i.cardType === DayScheduleStepsTypeNew.start
        ) as VisitCardsNewI | VisitBreaksNewI;
        startLoc = startStep.geolocation;
      }
      setStartLocation({
        location: startLoc,
        cardType: DayScheduleStepsTypeNew.start,
        status: DayScheduleStepsTabsTypeNew.activeSteps,
      });

      let endStep = routerValue.routeData.steps.activeSteps.find(
        (i) => i.cardType === DayScheduleStepsTypeNew.end
      ) as VisitCardsNewI | VisitBreaksNewI;
      setEndLocation({
        location: endStep.geolocation,
        cardType: DayScheduleStepsTypeNew.end,
        status: DayScheduleStepsTabsTypeNew.activeSteps,
      });

      setWaypoints(
        (
          routerValue.routeData.steps.activeSteps.filter(
            (i) => i.cardType === DayScheduleStepsTypeNew.service &&
              i.status !== DayScheduleStepsStatusesNew.missed
          ) as VisitCardsNewI[]
        ).map((step) => {
          return {
            locationName: step.location.title,
            id: step.id,
            cardType: step?.cardType,
            status: DayScheduleStepsTabsTypeNew.activeSteps,
            isLocationOverdue: step.isLocationOverdue,
            location: new google.maps.LatLng({
              lat: Number(step.geolocation?.lat) || 0,
              lng: Number(step.geolocation?.lng) || 0,
            }),
            stopover: true,
            appointment: step.appointment,
          };
        })
      );

      setMissedAppointments((routerValue.routeData.steps.activeSteps
        .filter(i => i.status === DayScheduleStepsStatusesNew.missed) as VisitCardsNewI[])
        .map((step) => {
          return {
            locationName: step.location.title,
            id: step.id,
            cardType: step?.cardType,
            status: DayScheduleStepsTabsTypeNew.activeSteps,
            isLocationOverdue: step.isLocationOverdue,
            location: new google.maps.LatLng({
              lat: Number(step.geolocation?.lat) || 0,
              lng: Number(step.geolocation?.lng) || 0,
            }),
            stopover: true,
            appointment: step.appointment,
          };
        })
      );

      setSkippedSteps(
        (routerValue.routeData.steps.skippedSteps as VisitCardsNewI[]).map(
          (step) => {
            return {
              locationName: step.location.title,
              id: step.id,
              cardType: step?.cardType,
              status: DayScheduleStepsTabsTypeNew.skippedSteps,
              location: new google.maps.LatLng({
                lat: Number(step.geolocation?.lat) || 0,
                lng: Number(step.geolocation?.lng) || 0,
              }),
              stopover: true,
            };
          }
        )
      );

      setCompletedSteps(
        (routerValue.routeData.steps.completedSteps as VisitCardsNewI[]).map(
          (step) => {
            return {
              locationName: step.location.title,
              id: step.id,
              cardType: step?.cardType,
              status: DayScheduleStepsTabsTypeNew.completedSteps,
              location: new google.maps.LatLng({
                lat: Number(step.geolocation?.lat) || 0,
                lng: Number(step.geolocation?.lng) || 0,
              }),
              stopover: true,
            };
          }
        )
      );
    } else {
      setIsLoading(false);
    }
  }, [routerValue.routeData, coords]);

  useEffect(() => {
    waypoints.length && setIsLoading(false);
    setRerender(true);
  }, [waypoints]);

  useEffect(() => {
    if (response) {
      const firstStep = response.routes[0]?.legs[0]?.steps[0];
      const firstPoint = firstStep?.path[0] as google.maps.LatLng;
      const secondPoint = firstStep?.path[1] as google.maps.LatLng;

      // Calculate the angle between the start and end locations of the first step
      const angle = google.maps.geometry.spherical.computeHeading(firstPoint, secondPoint);
      setStartLocationAngle(angle);

      // Reset start and end locations from routes (road locations)
      setStartPoint({
        location: {
          lat: firstPoint.lat(),
          lng: firstPoint.lng(),
        },
        cardType: DayScheduleStepsTypeNew.start,
        status: DayScheduleStepsTabsTypeNew.activeSteps,
      });
    }
  }, [response]);

  const renderCustomDashedRoute = () => {
    if (!response) return null;

    const legs = response.routes[0].legs;
    const polylines = [];
    const directions = {
      ...response,
      routes: [
        {
          ...response.routes[0],
          legs: legs.slice(0, legs.length - 1), // Exclude the last leg
        },
      ],
    };

    // Create regular polylines for all legs except the last one
    for (let i = 0; i < legs.length - 1; i++) {
      const legPath = legs[i].steps.flatMap((step: google.maps.DirectionsStep) =>
        google.maps.geometry.encoding.decodePath(step.polyline?.points as string)
      );

      polylines.push(
        <DirectionsRenderer
          key={i}
          options={{
            polylineOptions: {
              path: legPath,
              strokeWeight: 3,
              strokeColor: "#541F1B",
            },
            hideRouteList: true,
            suppressMarkers: true,
            suppressPolylines: false,
            directions,
            map: map,
          }}
        />
      );
    }

    return polylines;
  };

  const directionsCallback = (
    result: google.maps.DirectionsResult | null,
    status: google.maps.DirectionsStatus
  ) => {
    setRerender(false);
    if (!response) {
      if (status === "OK") {
        setResponse(result);
      } else {
        toast.error("Directions request returned no results.");
      }
    }
  };

  const showSidenav = (
    id: string,
    status: DayScheduleStepsTabsTypeNew,
    cardType: DayScheduleStepsTypeNew
  ) => {
    setCard(
      routerValue.routeData?.steps[status].find(
        (i) =>
          (cardType === DayScheduleStepsTypeNew.service && i.id === id) ||
          (cardType !== DayScheduleStepsTypeNew.service &&
            i.cardType === cardType)
      )
    );
    setOpened(true);
  };

  const GetTrafficModel = (model: TrafficModelE): google.maps.TrafficModel => {
    switch (model) {
      case TrafficModelE.bestguess:
        return google.maps.TrafficModel.BEST_GUESS;
      case TrafficModelE.optimistic:
        return google.maps.TrafficModel.OPTIMISTIC;
      case TrafficModelE.pessimistic:
        return google.maps.TrafficModel.PESSIMISTIC;
    }
  };

  function isCard(
    object?: VisitCardsNewI | VisitBreaksNewI
  ): object is VisitCardsNewI {
    return object?.cardType === DayScheduleStepsTypeNew.service;
  }

  function getContentForSecondaryLabel(item: any): string {
    return item.isLocationOverdue ? "Overdue" : item.appointment ? "Appointment" : "";
  }

  const headerTitle = <div className={agent ? styles["up-header--agent-mode"] : ""}>
    {agent
      ? (
        <>
          <div>Route for</div>
          <div>{agent.fullName}</div>
        </>
      )
      : moment().tz(currentUser!.timezone.value).format('MMM DD, YYYY - ddd')
    }
  </div>;

  return !isGeolocationAvailable ? (
    <div>Your browser does not support Geolocation</div>
  ) : !isGeolocationEnabled ? (
    <div>Geolocation is not enabled</div>
  ) : routerValue.loaded ? (
    <>
      <div className={styles["up-modal"]}>
        <Header
          title={headerTitle}
          hideReload={true}
          toggleBack={() => setRouterValue({ ...routerValue, opened: false })}
        />

        <LoadingSpinner isLoading={isLoading} height="300px">
          {startLocation &&
          endLocation &&
          waypoints &&
          routerValue.routeData?.steps.activeSteps?.length ? (
            <GoogleMap
              onLoad={m => setMap(m)}
              options={{
                mapId: "11a7b148a03577a0",
                fullscreenControl: false,
                mapTypeControl: false,
                zoomControl: false,
                streetViewControl: false,
              }}
              clickableIcons={false}
              mapContainerStyle={{
                width: "100vw",
                height: "100vh",
              }}
              zoom={18}
            >
              <>
                <PlaneMarker
                  angle={startLocationAngle}
                  position={{
                    lat: startPoint?.location.lat || startLocation.location.lat,
                    lng: startPoint?.location.lng || startLocation.location.lng,
                  }}
                  onClick={() =>
                    showSidenav(
                      startLocation.id,
                      startLocation.status,
                      startLocation.cardType
                    )
                  }
                />

                <MarkerF
                  position={{
                    lat: endLocation.location.lat,
                    lng: endLocation.location.lng,
                  }}
                  icon={{
                    url: FlagIcon,
                    anchor: new google.maps.Point(4, 30)
                  }}
                  onClick={() =>
                    showSidenav(
                      endLocation.id,
                      endLocation.status,
                      endLocation.cardType
                    )
                  }
                />

                {waypoints.map((p: any, index: number) => {
                  let color;
                  if (p.appointment) color = "#954638";
                  else if (p.isLocationOverdue) color = "#FF0901";
                  else color = "#FF8050";

                  return (
                    <div key={index}>
                      <CircleMarker
                        position={p.location}
                        circle={{ fill: color }}
                        text={{ content: index + 1 }}
                        primaryLabel={{
                          content: p.locationName,
                          color
                        }}
                        secondaryLabel={{
                          content: getContentForSecondaryLabel(p),
                          color
                        }}
                        onClick={() => showSidenav(p.id, p.status, p.cardType)}
                      />
                    </div>
                  );
                })}

                {missedAppointments.map((p: any, index: number) => (
                  <div key={index}>
                    <CircleMarker
                      position={p.location}
                      circle={{ fill: "#686868" }}
                      primaryLabel={{
                        content: p.locationName,
                        color: "#333",
                      }}
                      onClick={() => showSidenav(p.id, p.status, p.cardType)}
                    />
                  </div>
                ))}

                {skippedSteps.map((p: any, index: number) => (
                  <div key={index}>
                    <CircleMarker
                      position={p.location}
                      circle={{ fill: "#686868" }}
                      primaryLabel={{
                        content: p.locationName,
                        color: "#333",
                      }}
                      onClick={() => showSidenav(p.id, p.status, p.cardType)}
                    />
                  </div>
                ))}

                {completedSteps.map((p: any, index: number) => {
                  const color = p.appointment ? "#954638" : "#FF8050";
                  return (
                    <div key={index}>
                      <CircleMarker
                        position={p.location}
                        circle={{ fill: color }}
                        text={{ content: "completed" }}
                        primaryLabel={{
                          content: p.locationName,
                          color
                        }}
                        secondaryLabel={p.appointment && { content: "Appointment" }}
                        onClick={() => showSidenav(p.id, p.status, p.cardType)}
                      />
                    </div>
                  );
                })}

                {rerender && (
                  <DirectionsService
                    options={{
                      destination: endLocation.location,
                      origin: startLocation.location,
                      travelMode: google.maps.TravelMode.DRIVING,
                      optimizeWaypoints: false,
                      waypoints: waypoints.map(
                        (i: { location: any; stopover: any }) => ({
                          location: i.location,
                          stopover: i.stopover,
                        })
                      ),
                      avoidFerries:
                        routerValue.routeData?.day.status ===
                        DayScheduleTypeNew.started
                          ? currentUser?.routerSettings?.avoidFerries || agent?.profile.avoidFerries
                          : false,
                      avoidTolls:
                        routerValue.routeData?.day.status ===
                        DayScheduleTypeNew.started
                          ? currentUser?.routerSettings?.avoidTolls || agent?.profile.avoidTolls
                          : false,
                      avoidHighways:
                        routerValue.routeData?.day.status ===
                        DayScheduleTypeNew.started
                          ? currentUser?.routerSettings?.avoidHighways || agent?.profile.avoidHighways
                          : false,
                      unitSystem:
                        routerValue.routeData?.day.status ===
                        DayScheduleTypeNew.started
                          ? currentUser?.routerSettings?.unitSystem === UnitSystemE.metric
                          || agent?.profile.unitSystem === UnitSystemE.metric
                            ? google.maps.UnitSystem.METRIC
                            : google.maps.UnitSystem.IMPERIAL
                          : google.maps.UnitSystem.IMPERIAL,
                      drivingOptions:
                        routerValue.routeData?.day.status ===
                        DayScheduleTypeNew.started
                          ? {
                            departureTime: new Date(),
                            trafficModel: GetTrafficModel(
                              agent ? agent?.profile.trafficModel : currentUser?.routerSettings?.trafficModel as TrafficModelE
                            ),
                          }
                          : undefined,
                    }}
                    callback={directionsCallback}
                  />
                )}
              </>

              {response && renderCustomDashedRoute()}
            </GoogleMap>
          ) : (
            <div className={styles["up-modal-error"]}>
              There are no routing steps to show
            </div>
          )}
        </LoadingSpinner>
      </div>

      <SwipeableDrawer
        open={opened}
        onClose={() => setOpened(false)}
        onOpen={() => setOpened(true)}
        anchor="bottom"
        swipeAreaWidth={44}
        disableSwipeToOpen={true}
        classes={{ paper: styles["up-drawer"] }}
      >
        <div className={styles["up-puller"]} />
        <div className={styles["up-drawer-content"]}>
          <LoadingSpinner isLoading={isLoading}>
            <div className={styles["up-card"]}>
              {isCard(card) ? (
                <div className={styles["up-card"]}>
                  <VisitCard
                    item={card}
                    onActionToggle={console.log}
                  ></VisitCard>
                </div>
              ) : (
                <>
                  {card && (
                    <div className={styles["up-card"]}>
                      <DayScheduleBreak
                        onActionToggle={console.log}
                        item={card}
                      ></DayScheduleBreak>
                    </div>
                  )}
                </>
              )}
            </div>
          </LoadingSpinner>
        </div>
      </SwipeableDrawer>
    </>
  ) : (
    <div>Getting the location data</div>
  );
}

export default RouteView;
