import {
  apiForgotUserPassword,
  apiGetLoginStatus,
  apiGetLogoutToken,
  apiGetSessionToken,
  apiGetUserInfo,
  apiLogin,
  apiLogout,
} from "./Api/Auth.api.service";

export const Login = (body: any) => {
  return apiLogin(body)
    .then((resp) => {
      localStorage.setItem("csrf_token", resp.data.csrf_token);
      localStorage.setItem(
        "current_user",
        JSON.stringify(resp.data.current_user)
      );
      return resp;
    })
    .catch((err) => err);
};

export const Logout = () => {
  return apiGetLogoutToken().then((i) => {
    return apiLogout(i.data.logout_token)
      .then(() => {
        localStorage.removeItem("csrf_token");
        localStorage.removeItem("current_user");
        localStorage.removeItem("session_token");
      })
      .catch((err) => err);
  });
};

export const getLoginStatus = () => {
  return apiGetLoginStatus().catch((err) => err);
};

export const getSessionToken = () => {
  return apiGetSessionToken()
    .then((token) => {
      localStorage.setItem("session_token", token.data);
    })
    .catch((err) => err);
};

export const getUserInfo = () => {
  return apiGetUserInfo().catch((err) => err);
};
export const ForgotUserPassword = (mail: string) => {
  return apiForgotUserPassword({
    mail: mail,
    name: mail,
  }).catch((err) => err);
};
