import { useFormik } from "formik";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button, Link, TextField } from "@mui/material";

import styles from "./ResetPassword.module.scss";
import { toast } from "react-toastify";

function ResetPassword() {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("This field is required"),
    confirmPassword: Yup.string()
      .required("This field is required")
      .when("password", {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    } as {
      password: string;
      confirmPassword: string;
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      toast.info("Completed Successfully, please re-login");
    },
  });

  return (
    <div className={styles["up-container"]}>
      <h2 className={styles["up-title"]}>Reset Password</h2>
      <p className={styles["up-description"]}>Create new password text</p>

      <form onSubmit={formik.handleSubmit} className={styles["up-form"]}>
        <TextField
          classes={{ root: styles["up-form-password"] }}
          placeholder="Password"
          variant="filled"
          hiddenLabel={true}
          fullWidth={true}
          InputProps={{
            classes: {
              root: styles["up-form-password-input"],
              focused: styles["focused"],
            },
          }}
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          inputProps={{
            type: "password",
          }}
        />
        <TextField
          classes={{ root: styles["up-form-confirm-password"] }}
          placeholder="Confirm password"
          variant="filled"
          hiddenLabel={true}
          fullWidth={true}
          InputProps={{
            classes: {
              root: styles["up-form-confirm-password-input"],
              focused: styles["focused"],
            },
          }}
          id="confirmPassword"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          inputProps={{
            type: "password",
          }}
        />
        <Button
          type="submit"
          color={"inherit"}
          classes={{ root: styles["up-form-button"] }}
          variant="contained"
        >
          RESET
        </Button>
      </form>
      <Link
        underline="none"
        onClick={() => navigate(`/`)}
        classes={{ root: styles["up-form-link"] }}
      >
        Back to Login screen
      </Link>
    </div>
  );
}

export default ResetPassword;
