import { Moment } from "moment";

import { TimeFormatI } from "@interfaces";

const moment = require("moment-timezone");

export const formatHMToAMPMString = (
  hours: number,
  minutes: number
): string => {
  const timeformat = formatHMToAMPM(hours, minutes);
  return `${timeformat.hour}:${timeformat.minute} ${timeformat.period}`;
};

export const formatMomentToAMPM = (
  date: Moment | string,
  timezone: string
): string => {
  return TimeFormatToString(
    formatHMToAMPM(
      moment(date).tz(timezone).hour(),
      moment(date).tz(timezone).minute()
    )
  );
};

export const TimeFormatToString = (date: TimeFormatI): string => {
  return `${date.hour}:${date.minute} ${date.period}`;
};

export const changeAMPMString = (
  date: string,
  key: string,
  value: string
): string => {
  return TimeFormatToString(formatStringToTimeFormat(date, key, value));
};

export const formatNumberToString = (date: number): string => {
  return formatHMToAMPMString(
    Number(date.toString().padStart(4, "0").slice(0, 2)),
    Number(date.toString().padStart(4, "0").slice(2))
  );
};
export const formatTimeStringToNumber = (time: string): number => {
  const timeformat = formatStringToTimeFormat(time);
  let hour =
    timeformat.period == "PM"
      ? (+timeformat.hour + 12).toString()
      : +timeformat.hour;
  if (timeformat.period == "PM" && timeformat.hour == "12") {
    hour = 24;
  }
  return Number(hour + "" + timeformat.minute) === 2400
    ? 2359
    : Number(hour + "" + timeformat.minute);
};

export const formatStringToTimeFormat = (
  date: string,
  key?: string,
  value?: string
): TimeFormatI => {
  let arr = date.split(/:| /);
  return {
    hour: value && key === "hour" ? value : arr[0],
    minute: value && key === "minute" ? value : arr[1],
    period: value && key === "period" ? value : arr[2],
  };
};

export const formatHMToAMPM = (hours: number, minutes: number): TimeFormatI => {
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  if (hours === 0 && ampm === "PM") {
    hours = 12;
  }
  let newMinutes = (Math.ceil(minutes / 5) * 5).toString().padStart(2, "0");
  if (newMinutes === "60") {
    newMinutes = "00";
    hours = +1;
  }
  let newHours = hours.toString().padStart(2, "0");
  return {
    hour: newHours,
    minute: newMinutes,
    period: ampm,
  };
};

export const formatMilisecondToTimeFormat = (
  millisecondsFromMidnight: number
): TimeFormatI => {
  const duration = moment.duration(millisecondsFromMidnight, "seconds");
  return formatHMToAMPM(duration.hours(), duration.minutes());
};
export const formatTimeFormatToMilisecond = (time: TimeFormatI): number => {
  const stringtime = moment(TimeFormatToString(time), "h:mm A");
  const secondsFromMidnight = stringtime.diff(
    moment().startOf("day"),
    "seconds"
  );
  return secondsFromMidnight;
};

export const check12AMto00 = (time: string, timezone?: string): string => {
  const currentTime = timezone ? moment.utc(time).tz(timezone) : moment(time);

  if (currentTime.format("hh:mm A") === "12:00 AM") {
    return currentTime.format("00:mm A");
  } else {
    return currentTime.format("hh:mm A");
  }
};
export const checkDate12AMto00 = (date: string, timezone?: string): string => {
  const currentDate = timezone ? moment.utc(date).tz(timezone) : moment(date);

  if (currentDate.format("hh:mm A") === "12:00 AM") {
    return currentDate.format("MMM D, YYYY 00:mm A");
  } else {
    return currentDate.format("MMM D, YYYY hh:mm A");
  }
};
