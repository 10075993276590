import { AxiosResponse } from "axios";

import { customAuthApi, helperApi } from "./Settings.api.service";

export const apiLogin = (body: any): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.post(`/user/login?_format=json`, body, {});
};

export const apiLogout = (token: string): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.post(`/user/logout?_format=json&token=${token}`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};

export const apiGetSessionToken = (): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.get(`/session/token`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
export const apiGetLogoutToken = (): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.get(`/session/token/logout`, {
    headers: {
      "X-CSRF-Token": localStorage.getItem("session_token"),
    },
  });
};
export const apiGetLoginStatus = (): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.get(`/user/login_status?_format=json`);
};

export const apiGetUserInfo = (): Promise<AxiosResponse<any, any>> => {
  return helperApi.get("/user/current");
};

export const apiForgotUserPassword = (body: {
  mail: string;
  name: string;
}): Promise<AxiosResponse<any, any>> => {
  return customAuthApi.post("/user/password?_format=json", body);
};
