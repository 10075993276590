import Header from "components/Shared/Header/Header";
import RoutingSettings from "components/Shared/Profile/RoutingSettings/RoutingSettings";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { CheckRoutingPermission } from "shared/helpers/common.helper";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

import styles from "./Settings.module.scss";

function Settings() {
  const navigate = useNavigate();

  const { currentUser } = useContext(UserDataContext);

  return (
    <div className={styles["up-container"]}>
      <Header
        title={"Settings"}
        toggleBack={() => navigate('/')}
        hideReload={true}
      />
      {CheckRoutingPermission(currentUser!) && (
        <Accordion classes={{ root: styles["up-accordion-root"] }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Router settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RoutingSettings></RoutingSettings>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}

export default Settings;
