import axios, { AxiosError } from "axios";
import FormsHeader from "components/Shared/Forms/FormsHeader/FormsHeader";
import LocationsForm from "components/Shared/Locations/LocationsForm/LocationsForm";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserDataContext } from "shared/Contexts";
import { PermissionsList } from "shared/Enums";
import { checkPermissionsForEdit } from "shared/helpers/common.helper";
import {
  deleteLocation,
  editLocationData,
  getLocationData,
} from "shared/Services";

import { LocationFormI, LocationViewI } from "@interfaces";

import styles from "./LocationEdit.module.scss";
import { toast } from "react-toastify";

function LocationEdit() {
  const { currentUser } = useContext(UserDataContext);

  const [submitted, setSubmitted] = useState(0);
  const [locationInfo, setLocationInfo] = useState<LocationViewI | null>(null);
  const params = useParams();
  const navigate = useNavigate();

  const FormSubmit = (value: LocationFormI) => {
    editLocationData(params.locationId!, value).then(
      (data: AxiosError | any) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
        } else {
          toast.success("successfully completed!");
          navigate(`/locations/${params.locationId}`);
        }
      }
    );
  };

  useEffect(() => {
    params.locationId &&
      getLocationData(params.locationId).then((data) => {
        if (axios.isAxiosError(data)) {
          toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
        } else {
          setLocationInfo(data);
        }
      });
  }, []);

  const ToggleDelete = () => {
    params.locationId && deleteLocation(params.locationId).then((data: AxiosError | any) => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        toast.success("successfully deleted!");
        navigate(`/locations/`);
      }
    });
  };
  return (
    <div className={styles["up-container"]}>
      <FormsHeader
        title="Edit Location"
        onRightBtnToggle={() => setSubmitted(submitted + 1)}
        position={"absolute"}
        background="transparent"
        viewDeleteButton={checkPermissionsForEdit(
          currentUser!,
          PermissionsList.deleteAnyLocation,
          PermissionsList.deleteOwnLocation,
          locationInfo?.authorId || ""
        )}
        deleteToggle={() => ToggleDelete()}
        onCancelToggle={() => navigate(-1)}
      />
      {locationInfo && (
        <LocationsForm
          submitted={submitted}
          formValue={locationInfo}
          formFinished={FormSubmit}
        ></LocationsForm>
      )}
    </div>
  );
}

export default LocationEdit;
